<template>
	<v-sheet max-width="380" class="mx-auto" color="transparent">
		<v-form @submit.prevent="$v.$invalid ? null : submit()" ref="form" class="my-10">
			<v-text-field
				:error-messages="fieldErrors('code')"
				@input="$v.code.$touch()"
				@blur="$v.code.$touch()"
				v-model="code"
				placeholder="000000"
				v-mask="'######'"
				flat
			/>

			<v-btn block type="submit" color="primary" :disabled="$v.$invalid" :loading="loader"
				>Validar código</v-btn
			>
		</v-form>
	</v-sheet>
</template>

<script>
	import { mask } from 'vue-the-mask'
	import { required } from 'vuelidate/lib/validators'
	import validationMixin from '@/mixins/validationMixin'
	export default {
		directives: { mask },
		mixins: [ validationMixin ],
		validations: {
			code: { required },
		},
		validationMessages: {
			code: {
				required: 'Por favor, introduce el código',
			},
		},
		created() {},
		data() {
			return {
				errors: null,
				code: null,
				loader: false,
			}
		},
		methods: {
			submit() {
				this.loader = true
				this.checkCode()
			},
			resetForm() {
				// this.form = Object.assign({}, defaultForm)
				// this.$refs.sendpasscode.reset()
				// this.$v.$reset()
			},
			checkCode() {
				this.$axios
					.get( 'password/find/' + this.code )
					.then( ( response ) => {
						this.email = response.data.email

						setTimeout( () => {
							this.loader = false
							this.$emit( 'next', { code: this.code } )
						}, 2000 )
					} )
					.catch( ( error ) => {
						this.loader = false
						this.$swal( {
							title: 'Código inválido',
							text:
								'No hemos encontrado usuarios asociados al código de recuperación que ingresaste en nuestros registros',
							icon: 'error',
							showCancelButton: false,
							showConfirmButton: true,
						} )
						this.$swal.showValidationMessage( `Solicitud rechazada: ${error}` )
						setTimeout( () => location.reload, 5000 )
						this.error = true
					} )
			},
		},
	}
</script>
